
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Alert,
    Form,
    Input
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment"
import { Link } from "react-router-dom";
import url from "../../helpers/apiUrl"
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
//import "../Roles Management/rolemanagement.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class UpdateTemplate extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Template Management",
                    link: "template-managemnet"
                },
                {
                    title: "Update Template",
                    link: "#"
                },
            ],
            template_content: '',
            template_name: '',
            template_description: '',
            template_type: '',
            template_status: '',
            id: '',
            temp: false,
            prev_template_content: '',
            prev_template_description: '',
            prev_template_name: '',
            prev_template_status: '',
            prev_template_type: ''
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerContent = this.changeHandlerContent.bind(this);
    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("Update Template", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        const { template } = this.props.location.state
        console.log("template", template.template_content)
        this.setState({
            template_content: template.template_content,
            template_description: template.template_description,
            template_name: template.template_name,
            template_status: template.template_status,
            template_type: template.template_type,
            id: template.id,
            prev_template_content: template.template_content,
            prev_template_description: template.template_description,
            prev_template_name: template.template_name,
            prev_template_status: template.template_status,
            prev_template_type: template.template_type,
        })
        this.fillTemplate(template)
       console.log(this.state.template_content, "name") 
    }

    changeHandlerContent = (value) => {
        this.setState({ template_content: value })
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    fillTemplate(template){
        this.setState({
            template_content: template.template_content,
            template_description: template.template_description,
            template_name: template.template_name,
            template_status: template.template_status,
            template_type: template.template_type,
            id: template.id,
            prev_template_content: template.template_content,
            prev_template_description: template.template_description,
            prev_template_name: template.template_name,
            prev_template_status: template.template_status,
            prev_template_type: template.template_type,
        })
        console.log(this.state.template_content, "fillcontent")
        console.log(this.state.template_name, "fillname")
    }

    updateTemplate(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "id": this.state.id,
        "template_name": this.state.template_name,
        "template_description": this.state.template_description,
        "template_type": this.state.template_type,
        "template_content": this.state.template_content,
        "template_status": this.state.template_status
        });
        
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/templates/update/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            if(result.statusCode === "200"){
                
                toast(<div className='toast-message-container'>
                <h6>Template Management</h6>
                <p>Template updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            } else {
                alert(result)
            }
        })
        .catch(error => console.log('error', error));
    }
   
  
    render() {
        const { template } = this.props.location.state
        console.log(template.template_content, "render content")
        return (
            <React.Fragment>
                {this.state.temp ? <Alert color="success">
                    <strong>Template updated successfully.</strong>
                </Alert> : null}
                <Row>
                    <Col sm="6" style={{marginTop: "5px"}}>
                        <AvForm className="formContainer">
                        <AvField
                            name="template_name"
                            label="Template Name * (50 characters max.)"
                            maxLength="50"
                            className="w-100"
                            placeholder="Enter Template Name"
                            type="text"
                            errorMessage="Enter Template Name"
                            validate={{ required: { value: true } }}
                            value={
                                this.state.template_name
                            }
                            onChange
                            ={this.changeHandler}
                        />
                        <AvField
                            style={{marginTop: "5px"}}
                            className="w-100"
                            name="template_description"
                            label= {this.state.template_type === "Email" ? "Email Subject *" : "Template Description *"}
                            placeholder="Enter Template Description"
                            type="text"
                            errorMessage="Enter Template Description"
                            validate={{ required: { value: true } }}
                            value={
                                this.state.template_description
                            }
                            onChange
                            ={this.changeHandler}
                        />
                        </AvForm>
                    </Col>
                    <Col sm="6">
                        <Label for="example-password-input" className="col-sm-6 col-form-label"> Template Type *</Label>
                        <FormGroup row className="formContainer">
                            <Col sm="12">
                                <select className="selectFormContainer mb-0 w-100" name='template_type' disabled
                                    value={
                                        this.state.template_type
                                    }
                                    onChange={
                                        this.changeHandler
                                    }>
                                    <option value="" disabled selected hidden>Select</option>
                                    <option value="Email">Email</option>
                                    <option value="SMS">SMS</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                </select>
                            </Col>
                        </FormGroup>
                        <Label for="example-password-input" className="col-sm-6 col-form-label"> Status *</Label>
                        <FormGroup row className="formContainer">
                            <Col sm="12">
                                <select className="selectFormContainer mb-0 w-100" name='template_status'
                                    value={
                                        this.state.template_status
                                    }
                                    onChange={
                                        this.changeHandler
                                    }>
                                    <option value="" disabled selected hidden>Select</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Form method="post">
                                    {
                                        this.state.template_type == "Email" ? 
                                        
                                        <ReactSummernote
                                            value= {this.state.template_content}
                                            options={{
                                            height: 350,
                                            disableDragAndDrop: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link"]],
                                                ["view", [ "codeview"]]
                                            ]
                                            }}
                                            onChange={this.changeHandlerContent}
                                        />
                                        :
                                        <Col>
                                            <FormGroup row>
                                                <Col sm="12">
                                                <Input type='textarea' name='template_content' value={this.state.template_content} onChange={this.changeHandler} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    }
                                
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> 
                {
                    this.state.template_name === this.state.prev_template_name && this.state.template_description === this.state.prev_template_description && this.state.template_type === this.state.prev_template_type && this.state.template_status === this.state.prev_template_status && this.state.template_content === this.state.prev_template_content ?
                    <Button type="button" color="primary" disabled
                        onClick={
                            () => this.updateTemplate()
                        }
                        style={{cursor: "default"}}
                        className="waves-effect waves-light">Update Template
                    </Button> :
                    <Button type="button" color="primary"
                        onClick={
                            () => this.updateTemplate()
                        }
                        className="waves-effect waves-light">Update Template
                    </Button>
                }
                
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(UpdateTemplate);