import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Pagesregister from '../pages/Authentication/Register';
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import LockScreen from "../pages/Authentication/pages-lock-screen";


//My Pages

import ListManagemnet from "../pages/marketing/listmanagemnet"
import MarketingCampaign from "../pages/marketing/campaign"
import TemplateManagemnet from "../pages/marketing/templatemanagement"
import MarketingContacts from "../pages/marketing/contacts"
import CreateTemplate from "../pages/marketing/createtemplate"
import UpdateTemplate from "../pages/marketing/updatetemplate";
import ListContacts from "../pages/marketing/listcontacts"
import Profile from "../pages/profile/index"

const authProtectedRoutes = [

  { path: "/campaign", component: MarketingCampaign },
  { path: "/list-management", component: ListManagemnet },
  { path: "/template-managemnet", component: TemplateManagemnet },
  { path: "/contacts", component: MarketingContacts },
  { path: "/list-contacts", component: ListContacts },
  { path: "/create-template", component: CreateTemplate },
  { path: "/update-template", component: UpdateTemplate },
  { path: "/profile", component: Profile },

  { path: "/", exact: true, component: () => <Redirect to="/campaign" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: "/register", component: Pagesregister },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/pages-lock-screen', component: LockScreen },
];

export { authProtectedRoutes, publicRoutes };
