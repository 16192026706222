import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Input,
    UncontrolledTooltip
} from "reactstrap";

import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import url from "../../helpers/apiUrl"
import Moment from "moment"
import { data } from 'jquery';
import { RotatingLines } from  'react-loader-spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css"



class ListManagemnet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                },
                {
                    title: "Campaign",
                    link: "campaign"
                }, {
                    title: "List Management",
                    link: "#"
                },
            ],
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            modal_add: false,
            modal_edit: false,
            id: '',
            list: [],
            list_dropdown: [],
            list_name: '',
            list_name_filter:'',
            list_description: '',
            list_status: '',
            list_status_filter: '',
            prevList_name: '',
            prevList_description: '',
            prevList_status: '',
            list_updated_at: '',
            list_created_at: '',
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            modal_add_cal: false,
            updateLists: false,
            updateListFail: false,
            createListFail: false,
            createLists: false,
            DateAlert:false,
            isLoadingSave: false,
        }
        this.viewAllList = this.viewAllList.bind(this);
        this.createList = this.createList.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.updateList = this.updateList.bind(this);
        this.fillList = this.fillList.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }
    

    componentDidMount() {
        this.props.setBreadcrumbItems("List Management", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
            );
        }
        this.viewAllList()
        this.viewAllListDropdown()

       }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2",fromDate:'',toDate:'' })
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                this.viewAllList()
            }, 1500);
        }
    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal
        }));
    }
    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewAllList()
        }, 1500);
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }

    viewAllList(){
        this.setState({isLoadingSave : true})
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }
        

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "list_name": this.state.list_name_filter,
            "list_status": this.state.list_status_filter,
            "from_date": fromDate,
            "to_date": toDate
        });
        console.log("list filter", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/lists/view/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave : false})

                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                } 
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    id: result.body[i].id,
                    list_name: result.body[i].list_name,
                    list_description: result.body[i].list_description,
                    list_status: result.body[i].list_status,  
                    total_contact: result.body[i].total_contact,                  
                    list_created_at:  Moment(result.body[i].list_created_at).format('YYYY/MM/DD HH:mm:ss'),
                    list_updated_at:  Moment(result.body[i].list_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="button"
                                onClick={
                                    () => this.fillList(result.body[i])
                                }
                                style={
                                    { marginRight: 10 }
                                }
                                id="tooltip_edit_list"
                                color="primary"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ti-pencil"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_list">
                                    Edit 
                            </UncontrolledTooltip>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ list_name: result.body[i].list_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_list"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_list">
                                Delete 
                            </UncontrolledTooltip>
                            <Link to={{
                                    pathname: "/list-contacts",
                                    state: {
                                       list: result.body[i]
                                    }
                                }}>
                                    <Button type="button"
                                        
                                        style={
                                           {marginLeft: 10}
                                        }
                                        id="tooltip_view_listcontact"
                                        color="success"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_view_listcontact">
                                        View
                                    </UncontrolledTooltip>
                                </Link>
                        </div>
                   })   
                }
                this.setState({ list: array })
            }
            else{
                this.setState({ list: [] })
            }
        })
        .catch(error => console.log('error', error));
    }
    viewAllListDropdown(){

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/lists/view/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave : false})

                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                } 
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    id: result.body[i].id,
                    list_name: result.body[i].list_name,
                    list_description: result.body[i].list_description,
                    list_status: result.body[i].list_status,  
                    total_contact: result.body[i].total_contact,                  
                    list_created_at:  Moment(result.body[i].list_created_at).format('YYYY/MM/DD HH:mm:ss'),
                    list_updated_at:  Moment(result.body[i].list_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                    button: <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="button"
                                onClick={
                                    () => this.fillList(result.body[i])
                                }
                                style={
                                    { marginRight: 10 }
                                }
                                id="tooltip_edit_list"
                                color="primary"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ti-pencil"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_list">
                                    Edit 
                            </UncontrolledTooltip>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ list_name: result.body[i].list_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_list"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_list">
                                Delete 
                            </UncontrolledTooltip>
                            <Link to={{
                                    pathname: "/list-contacts",
                                    state: {
                                       list: result.body[i]
                                    }
                                }}>
                                    <Button type="button"
                                        
                                        style={
                                           {marginLeft: 10}
                                        }
                                        id="tooltip_view_listcontact"
                                        color="success"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ion ion-md-arrow-round-forward"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_view_listcontact">
                                        View
                                    </UncontrolledTooltip>
                                </Link>
                        </div>
                   })   
                }
                this.setState({ list_dropdown: array })
            }
            else{
                this.setState({ list: [] })
            }
        })
        .catch(error => console.log('error', error));
    }

    createList(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "list_name": this.state.list_name,
        "list_description": this.state.list_description,
        "list_status": this.state.list_status
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/lists/create/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_add()
                this.viewAllList()
                // this.setState({
                //    createLists: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         createLists: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            else{
                // this.setState({
                //     createListFail: true
                //  })
                //  setTimeout(() => {
                //      this.setState({
                //         createListFail: false
                //      })
                //  }, 3000) 
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List could not be created.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    updateList(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "id": this.state.id,
        "list_name": this.state.list_name,
        "list_description": this.state.list_description,
        "list_status": this.state.list_status
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/lists/update/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_edit()
                this.viewAllList()
                this.setState({
                    id: '',
                    list_name: '',
                    list_description:'',
                    list_status: ''
                })
                // this.setState({
                //     updateLists: true
                //  })
                //  setTimeout(() => {
                //      this.setState({
                //         updateLists: false
                //      })
                //  }, 3000)
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            else{
                // this.setState({
                //     updateListFail: true
                //  })
                //  setTimeout(() => {
                //      this.setState({
                //         updateListFail: false
                //      })
                //  }, 3000) 
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List could not be updated.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    deleteList(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/lists/delete/` + this.state.id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode==="200"){
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List deleted successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }else{
                toast(<div className='toast-message-container'>
                <h6>List Management</h6>
                <p>List could not be deleted.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
            
            this.viewAllList()
            this.setState({
                list_name: '',
                list_status: '',
                list_created_at: '',
                created_at_filter: ''
            })
            
        })
        .catch(error => console.log('error', error));
    }

   

    fillList(list){
        this.tog_edit()
        console.log("list", list)
        this.setState({
            id: list.id,
            list_name: list.list_name,
            list_description: list.list_description,
            list_status: list.list_status,
            prevList_name: list.list_name,
            prevList_description: list.list_description,
            prevList_status: list.list_status
        })

    }

    tog_add() {
        if (this.state.modal_add === true) {
            this.setState({
                list_name: '',
                list_description: '',
                list_status: ''
            })
        }
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
        if (this.state.modal_edit_ticket !== false) {
            this.setState({
                id: '',
                list_name: '',
                list_description: '',
                list_status: ''
            })
        }
    }

    clearFilter(){
        this.setState({
            list_name_filter: '',
            list_status_filter: '',
            list_created_at: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.viewAllList()
        }, 100)
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>List Management</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
  
    render() {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',

                    width: 150
                },
                {
                    label: 'List Name',
                    field: 'list_name',

                    width: 270
                },
                {
                    label: 'List Description',
                    field: 'list_description',

                    width: 200
                },
                {
                    label: 'Total Contacts',
                    field: 'total_contact',

                    width: 200
                },
                {
                    label: 'List Status',
                    field: 'list_status',

                    width: 10
                }, {
                    label: 'Created At',
                    field: 'list_created_at',

                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'list_updated_at',

                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.list
        };
        return (
            <React.Fragment>
                
               
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete List</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.list_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteList();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        onClick={
                            this.tog_add
                        }
                        color="info"
                        className="waves-effect waves-light buttons-primary">
                       Add List</Button>
                </div>

                 <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">List Name</Label>
                                        <Col>
                                            <select className="form-control" name='list_name_filter'
                                                value={
                                                    this.state.list_name_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                               <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.list_dropdown.map((lists) => (
                                                        <option value={lists.list_name}>{lists.list_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Col>
                            
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">List Status</Label>
                                        <Col>
                                            <select className="form-control" name='list_status_filter'
                                                value={
                                                    this.state.list_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className="text-left" style={{ marginTop: 20 }}>
                                    <Button type="button"
                                        onClick={
                                            this.viewAllList
                                        }
                                        color="warning"
                                        className="waves-effect waves-light buttons-primary">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>
                                    { this.state.created_at_filter === '' && this.state.list_status_filter === '' && this.state.list_name_filter === '' ?
                                        <Button type="button" disabled
                                            style={{ marginLeft: 10, cursor: "default" }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-disabled">

                                            Clear Filter
                                        </Button>:
                                        <Button type="button"
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-ligh filter-buttons">

                                            Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>
                                {/*
                                
                                */}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.state.createLists ? <Alert color="success">
                    <strong>List created successfully.</strong>
                </Alert> : null}
                {this.state.updateLists? <Alert color="success">
                    <strong>List updated successfully.</strong>
                </Alert> : null}
                {this.state.createListFail ? <Alert color="danger">
                    <strong>Creation failed.</strong>
                </Alert> : null}
                {this.state.updateListFail ? <Alert color="danger">
                    <strong>Updation failed.</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped
                                    data={data}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllList
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllList
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllList
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>

                {/*Create List*/}
                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Add Details
                        </ModalHeader>
                        <ModalBody>
                            {this.state.temp ? <Alert color="danger">
                                <strong>Duplicate entry for email. Try new email.</strong>
                            </Alert> : null}
                            {this.state.temp2 ? <Alert color="danger">
                                <strong>Duplicate entry for phone. Try new phone number.</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="list_name"
                                    className="w-100"
                                    label="List Name * (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter List Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.list_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="list_description"
                                    className="w-100"
                                    label="List Description *"
                                    placeholder="Enter List Description"
                                    type="text"
                                    errorMessage="Enter Description"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.list_description
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                            
                            </AvForm>
                            <Label for="example-password-input" className="col-form-label">Status *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='list_status'
                                        value={
                                            this.state.list_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close
                            </Button>
                            {
                                this.state.list_name === "" || this.state.list_description === "" || this.state.list_status === "" ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createList()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add List
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createList()
                                        }
                                        className="waves-effect waves-light">Add List</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                {/*Update List*/}
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            <AvForm className="formContainer">
                                <AvField
                                    name="list_name"
                                    className="w-100"
                                    label="List Name * (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter List Name"
                                    type="text"
                                    errorMessage="Enter Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.list_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="list_description"
                                    className="w-100"
                                    label="List Description *"
                                    placeholder="Enter List Description"
                                    type="text"
                                    errorMessage="Enter Description"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.list_description
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                            
                            </AvForm>
                            <Label for="example-password-input" className=" col-form-label">Status *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer w-100 mb-0" name='list_status'
                                        value={
                                            this.state.list_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>


                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close
                            </Button>
                            {
                                this.state.list_name === this.state.prevList_name && this.state.list_description === this.state.prevList_description && this.state.list_status === this.state.prevList_status ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateList()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Update List
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateList()
                                        }
                                        className="waves-effect waves-light">Update List</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(ListManagemnet);