/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

export const ACTIVATE_NON_AUTH_LAYOUT = 'non_auth_layout';

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

//Sidebaar
export const TOGGLE = 'toggle';
export const TOGGLE_RIGHT_SIDEBAR = 'toggle_right_sidebar';
export const HIDE_RIGHT_SIDEBAR = 'hide_right_sidebar';