import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    Alert,
    Label
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import url from "../../helpers/apiUrl"
import { AvForm, AvField } from "availity-reactstrap-validation";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';


class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                }, {
                    title: "Profile",
                    link: "#"
                },
            ],
            firstname: '',
            prevFirstname: '',
            prevLastname: '',
            prevPhone: '',
            lastname: '',
            phone: '',
            email: '',
            role: '',
            password: '',
            retype_password: '',
            temp: false,
            temp2: false,
            temp3: false,
            alert_verification_phone: false
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }


    componentDidMount() {
        this.props.setBreadcrumbItems("Users Profile", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.getUserInfo()
    }

    getUserInfo() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/profile/userdetail`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log(result, "profiles data")
                this.setState({
                    firstname: result.body.first_name,
                    prevFirstname: result.body.first_name,
                    lastname: result.body.last_name,
                    prevLastname: result.body.last_name,
                    email: result.body.email,
                    phone: result.body.phone,
                    prevPhone: result.body.phone,
                    role: result.body.role === 1 ? "Admin" : result.body.role === 2 ? "Support Manager" : result.body.role === 3 ? "Marketing" : "Support Engineer"
                })

            })
            .catch(error => console.log('error', error));
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    disableToast(status, msg){
        let toastDiv = document.querySelector('.toast-error');
        let toastSuccess = document.querySelector('.toast-success');
        if(status == 'fail'){

            if(toastDiv == null){
                toast(<div className='toast-message-container'>
                <h6>Profile</h6>
                <p>{msg}</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        }else {

            if(toastSuccess == null){
                toast(<div className='toast-message-container'>
                <h6>Profile</h6>
                <p>{msg}</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }

        }
       

        
    }

    updateUser() {
        if(this.state.phone.length >= 21){
            // this.setState({
            //     alert_verification_phone: true,
            // }) 
            // setTimeout(() => {
            //     this.setState({
            //         alert_verification_phone: false,
            //     })
            // }, 3000)
            this.disableToast('fail', 'Please enter a valid mobile number.')
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "first_name": this.state.firstname,
            "last_name": this.state.lastname,
            "email": this.state.email,
            "phone": this.state.phone
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/profile/update`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                if (result.statusCode === "200") {

                    this.setState({
                        prevFirstname:this.state.firstname,
                        prevLastname:this.state.lastname,
                        prevPhone:this.state.phone
                       })
                   this.disableToast('success', 'Profile updated successfully.');
                } else {
                    this.disableToast('fail', 'Something went wrong while updating the profile.')
                }
            })
            .catch(error => console.log('error', error));
    }

    changePassword() {
        console.log("password", this.state.password !== this.state.retype_password)
        if (this.state.password !== this.state.retype_password) {
            
           this.disableToast('fail', 'Passwords do not match.')
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "password": this.state.password
            });
            console.log(raw)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${url}/profile/update/password`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.statusCode === "200") {

                        this.setState({
                            password:"",
                            retype_password:""
                        })
                       this.disableToast('success', 'Password updated successfully.')
                    } else {
                        this.disableToast('fail', 'Password could not be updated.')
                    }
                })
                .catch(error => console.log('error', error));

        }


    }


    render() {
        console.log(this.state.password, this.state.retype_password)
        return (
            <React.Fragment>
                {this.state.temp ? <Alert color="success">
                    <strong>User updated successfully</strong>
                </Alert> : null}
                {this.state.alert_verification_phone ? <Alert color="danger">
                                <strong>Please provide a valid mobile number</strong>
                            </Alert> : null}

                <Row>
                    <Col xs="6" >
                        <Card>
                            <CardBody className="text-left">

                                <AvForm className="formContainer">

                                    <AvField
                                        name="firstname"
                                        className="w-100"
                                        label="First Name "
                                        placeholder="Type Something"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={this.state.firstname}
                                        onChange={this.changeHandler}
                                    />
                                    <AvField
                                        name="lastname"
                                        className="w-100"
                                        label="Last Name"
                                        placeholder="Type Something"
                                        type="text"
                                        errorMessage="Enter Name"
                                        validate={{ required: { value: true } }}
                                        value={this.state.lastname}
                                        onChange={this.changeHandler}
                                    />
                                    <AvField
                                        name="email"
                                        className="w-100"
                                        label="E-Mail"
                                        placeholder="Enter Valid Email"
                                        type="email"
                                        disabled
                                        errorMessage="Invalid Email"
                                        validate={{
                                            required: { value: true },
                                            email: { value: true }
                                        }}
                                        value={this.state.email}
                                        onChange={this.changeHandler}
                                    />

                                    <Label for="example-password-input" className="col-form-label">Phone Number </Label>
                                    <PhoneInput 
                                    
                                        style={{ backgroundColor: '#474548' }}
                                        country={'ae'}
                                        isValid ={true}
                                        value={this.state.phone}
                                        onChange={phone => this.setState({ phone })}
                                        className="w-100"
                                    
                                    />

                                    <AvField
                                        name="role"
                                        className="w-100"
                                        label="Role"
                                        placeholder="Type Something"
                                        type="text"
                                        disabled
                                        errorMessage="Enter Role"
                                        validate={{ required: { value: true } }}
                                        value={this.state.role}
                                        onChange={this.changeHandler}
                                    />
                                </AvForm>
                                {
                                    this.state.firstname === this.state.prevFirstname && this.state.lastname === this.state.prevLastname && this.state.phone === this.state.prevPhone ?
                                        <div className='text-center'><Button type="button" color="primary" style={{ width: "300px", cursor: "default" }}
                                            onClick={
                                                () => this.updateUser()
                                            }
                                            disabled
                                            className="waves-effect waves-light ">
                                            Update
                                        </Button></div>
                                        : <div className='text-center'><Button type="button" color="primary" style={{ width: "300px" }}
                                            onClick={
                                                () => this.updateUser()
                                            }
                                            className="waves-effect waves-light ">
                                            Update
                                        </Button></div>
                                }

                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="6">
                        {this.state.temp2 ? <Alert color="success">
                            <strong>Password updated successfully</strong>
                        </Alert> : null}
                        {this.state.temp3 ? <Alert color="danger">
                            <strong>Password did not match.</strong>
                        </Alert> : null}

                        <Card>
                            <CardBody className="text-center">
                                <h5 className='mb-5'>Change Password</h5>
                                <Col xs="12">
                                    <AvForm className="formContainer">
                                        <AvField
                                            name="password"
                                            className="w-100"
                                            value={this.state.password}
                                            type="password"
                                            placeholder="Password"
                                            errorMessage="Enter password"
                                            onChange={
                                                this.changeHandler
                                            }
                                            validate={{
                                                pattern: {
                                                    value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])",
                                                    errorMessage: "Please use 1 alphabet 1 number and 1 special character."
                                                },
                                                maxLength: { value: 14, errorMessage: "Max 14 characters required." },
                                                minLength: { value: 6, errorMessage: "Min 6 characters required." }
                                            }}
                                        />
                                        <AvField
                                            name="retype_password"
                                            className="w-100"
                                            value={this.state.retype_password}
                                            type="password"
                                            placeholder="Re-type Password"
                                            errorMessage="Passwords do not match."
                                            onChange={
                                                this.changeHandler
                                            }
                                            validate={{
                                                match: { value: "password" }
                                            }}
                                        />

                                    </AvForm>
                                    {
                                        this.state.password === ""  || this.state.retype_password === "" || this.state.password != this.state.retype_password?
                                            <Button type="button" color="primary" style={{ width: "300px", cursor: "default" }}
                                                onClick={
                                                    () => this.changePassword()
                                                }
                                                disabled
                                                className="waves-effect waves-light ">
                                                Change Password
                                            </Button> : <Button type="button" color="primary" style={{ width: "300px" }}
                                                onClick={
                                                    () => this.changePassword()
                                                }

                                                className="waves-effect waves-light ">
                                                Change Password
                                            </Button>
                                    }

                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(Profile);