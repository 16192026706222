import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Input,
    Form,
    UncontrolledTooltip
} from "reactstrap";

import { MDBDataTable, MDBInput } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { RotatingLines } from  'react-loader-spinner'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import url from "../../helpers/apiUrl"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import Moment from "moment"
import country from './country';
import "./marketing.css"
import DataTable,{ createTheme }  from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

createTheme('solarized', {
    text: {
      primary: 'white',
      secondary: 'white',
    },
    background: {
      default: '#313035',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#56555A',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');
class MarketingContacts extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
            breadcrumbItems: [
                {
                    title: "One Dash",
                    link: "dashboard"
                },
                {
                    title: "Campaign",
                    link: "campaign"
                }, {
                    title: "Contacts",
                    link: "#"
                },
            ], 
            success_confirm: false,
            emailValidation: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            modal_add: false,
            modal_add_file: false,
            modal_edit: false,
            contacts: [],
            contacts_filtered:[],
            list: [],
            contact_list_id: '',
            contact_list_id_filter: '',
            contact_status_filter: '',
            contact_first_name: '',
            contact_last_name: '',
            contact_email: '',
            contact_country: '',
            country_filter: '',
            contact_mobile: '',
            contact_status: '',
            id: '',
            prev_list_id: '',
            prev_first_name: '',
            prev_last_name: '',
            prev_email: '',
            prev_country: '',
            prev_mobile: '',
            prev_status: '',
            prev_id: '',
            contact_created_at: '',
            contact_updated_at: '',
            checkedId: [],
            list_id: '',
            selectedFile: null,
            csvfile: '',
            temp: false,
            temp2: false,
            csv_alert: false,
            isLoading: true,
            contact_list_ids: '',
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            modal_add_cal: false,
            alert_verification_phone: false,
            createCont: false,
            createContFail: false,
            updateCont: false,
            updateContFail: false,
            bulkimport: false,
            bulkDeleteAlert: false,
            importCsvMessage: '',
            DateAlert:false,
            isLoadingSave: false,
            toggledClearRows:false
        };
        this.tog_add = this.tog_add.bind(this);
        this.tog_edit = this.tog_edit.bind(this);
        this.tog_add_file = this.tog_add_file.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.viewAllContacts = this.viewAllContacts.bind(this);
        this.createContacts = this.createContacts.bind(this);
        this.viewAllList = this.viewAllList.bind(this);
        this.deleteContacts = this.deleteContacts.bind(this);
        this.updateContacts = this.updateContacts.bind(this);
        this.fillContacts = this.fillContacts.bind(this);
        this.checkUser = this.checkUser.bind(this);
        this.pushContacts = this.pushContacts.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.tog_add_cal = this.tog_add_cal.bind(this);
        this.tog_add_cal2 = this.tog_add_cal2.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.bulkDelete = this.bulkDelete.bind(this);
        this.bulkimportToList = this.bulkimportToList.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    

    componentDidMount() {
        this.props.setBreadcrumbItems("Contacts", this.state.breadcrumbItems);
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.viewAllContacts()
        this.viewAllList()
        
    }
    setSearchFilter = (e) => {
        this.setState({search:e})
        let array = []
        if(e===""){
            console.log(array)
            this.setState({contacts_filtered:this.state.contacts})
            return
        }
        for(let i = 0; i< this.state.contacts.length; i++){
            if(this.state.contacts[i].id.toString().toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_first_name.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_last_name.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_email.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_mobile.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_country.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_status.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_created_at.toLowerCase().includes(e.toLowerCase()) || this.state.contacts[i].contact_updated_at.toLowerCase().includes(e.toLowerCase())){
                array.push(this.state.contacts[i])
            }
        }
        //console.log(array)
        this.setState({contacts_filtered:array});
    }
    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2" ,fromDate:'',toDate:''})
            this.tog_add_cal()
        }
        else{
            setTimeout(() => {
                this.viewAllContacts()
            }, 1500);
        }
    }

    tog_add_cal() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal
        }));
    }
    tog_add_cal2() {
        this.setState(prevState => ({
            modal_add_cal: !prevState.modal_add_cal,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewAllContacts()
        }, 1500);
    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: this.formatBytes(file.size)
        })
        );

        this.setState({ selectedFiles: files });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] }); 
      };


    importFile(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var formdata = new FormData();
        
        formdata.append("csvfile",  this.state.selectedFile, this.state.selectedFile.name);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/importcsv`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_add_file()
                this.viewAllContacts()
                // this.setState({
                //     temp: true,
                //     importCsvMessage: result.message
                // })
                // setTimeout(() => {
                //     this.setState({
                //         temp: false,
                //         importCsvMessage: ''
                //     })
                // }, 3000);
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>{result.message}</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                
            }
            else if(result.error){
                // this.setState({
                //     csv_alert: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         csv_alert: false
                //     })
                // }, 3000);
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Something went wrong while importing contacts.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    checkUser(e, id){
        const checked = e.target.checked;
        var i = id-1
        var array = this.state.contacts
        this.setState({
            contacts: array,
            contacts_filtered:array
        })
        
        if (checked) {
            var array = this.state.checkedId
            array.push(id)
            this.setState({
                checkedId: array
            })
          } else {
            var array = this.state.checkedId
            var carIndex = array.indexOf(id);
            array.splice(carIndex, 1);
          }
          console.log(this.state.checkedId)
          this.viewAllContacts()
    }
    handleCheckboxChange(state){
        console.log(state.selectedRows)
        let array = [];
        for(let i=0;i<state.selectedRows.length;i++){
            array.push(state.selectedRows[i].id)
        }
        console.log(array)
        this.setState({checkedId:array})
    }
    pushContacts(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "list_id": this.state.contact_list_ids,
        "array_id": this.state.checkedId
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/updatelist/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                
                this.setState({
                    bulkimport: false ,contact_list_ids:""
                })
                this.setState({  toggledClearRows: !this.state.toggledClearRows})
                
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>{result.message }</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                
            }else{
                this.setState({  toggledClearRows: !this.state.toggledClearRows})
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>{result.message }</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    viewAllContacts(){
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "contact_country": this.state.country_filter,
            "contact_status": this.state.contact_status_filter,
            "from_date": fromDate,
            "to_date": toDate,
            "list_id": this.state.contact_list_id_filter
          });


        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/view/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.error === "Invalid Token"){
                localStorage.removeItem('token');
                sessionStorage.clear();
                this.props.history.push('/login') 
            }
            var array = []
            if(result.statusCode === "200"){
                this.setState({isLoadingSave : false})

                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }
                for(let i = 0; i < result.body.length; i++){
                    array.push({
                        ischecked: false,
                        check: <Input type='checkbox' checked = {this.state.checkedId.includes(result.body[i].id) ? true : false} style={{marginLeft: 10}}  onChange={(e) => this.checkUser(e, result.body[i].id)} />,
                        id:  result.body[i].id,
                        contact_list_id: result.body[i].list_id,
                        contact_first_name: result.body[i].contact_first_name,
                        contact_last_name: result.body[i].contact_last_name,
                        contact_country: result.body[i].contact_country,
                        contact_email: result.body[i].contact_email,
                        contact_mobile: result.body[i].contact_mobile,
                        contact_status: result.body[i].contact_status,
                        contact_created_at: Moment(result.body[i].contact_created_at).format('YYYY/MM/DD HH:mm:ss'),
                        contact_updated_at: Moment(result.body[i].contact_updated_at).format('YYYY/MM/DD HH:mm:ss'),
                        list_name: result.body[i].list_name,
                        button: <div style={{display: "flex", justifyContent: "center"}}>
                            <Button type="button"
                                onClick={
                                    () => this.fillContacts(result.body[i])
                                }
                                style={
                                    { marginRight: 10 }
                                }
                                color="primary" id="tooltip_edit_contact"
                                className="waves-effect waves-light buttons-secondary">
                                <i className="ti-pencil"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_edit_contact">
                                Edit 
                            </UncontrolledTooltip>
                            <Button type="button" color="danger"
                                onClick={
                                    () => this.setState({ contact_first_name: result.body[i].contact_first_name,
                                        contact_last_name: result.body[i].contact_last_name, alert_confirm: true, id: result.body[i].id })
                                }
                                className="waves-effect waves-light buttons-secondary"
                                id="tooltip_delete_contact"><i className="ti-trash"></i>
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="tooltip_delete_contact">
                                Delete 
                            </UncontrolledTooltip>
                        </div>
                    })
                }
                this.setState({ contacts: array,contacts_filtered:array, isLoading: false })
            }
            else{
                this.setState({ contacts: [],contacts_filtered:[] })
            }
            
        })
        .catch(error => console.log('error', error));
    
    }

    viewAllList(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/lists/view/active`, requestOptions)
        .then(response => response.json())
        .then(result => {
            var array = []
            if(result.statusCode === "200"){
                for (let i = 0; i < result.body.length; i++) {
                   array.push({
                    id: result.body[i].id,
                    list_name: result.body[i].list_name,              
                   })   
                }
            }
            this.setState({ list: array })
        })
        .catch(error => console.log('error', error));
    }

    createContacts(){
        
        if(this.state.contact_mobile.length >= 21){
            // this.setState({
            //     alert_verification_phone: true,
            // }) 
            // setTimeout(() => {
            //     this.setState({
            //         alert_verification_phone: false,
            //     })
            // }, 3000) 
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Please add a valid mobile number.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!this.state.contact_email.match(pattern)){
            // this.setState({
            //     emailValidation: true   
            // })
            // setTimeout(() => {
            //     this.setState({
            //         emailValidation: false
            //     })
            // }, 3000);
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Please add a valid email address.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return; 
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "list_id": this.state.contact_list_id,
        "first_name": this.state.contact_first_name,
        "last_name": this.state.contact_last_name,
        "contact_email": this.state.contact_email,
        "contact_country": this.state.contact_country,
        "contact_mobile": this.state.contact_mobile.substring(0,1)==="+" ? this.state.contact_mobile.substring(1) : this.state.contact_mobile,
        "contact_status": this.state.contact_status
        });
        console.log("Create raw", raw)

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/create/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_add()
                this.viewAllContacts()
                // this.setState({
                //     createCont: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         createCont: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Contact created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            }
            else{
                this.tog_add()
                // this.setState({
                //     createContFail: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         createContFail: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Contact already exists.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    updateContacts(){
        
        if(this.state.contact_mobile.length >= 21){
            // this.setState({
            //     alert_verification_phone: true,
            // }) 
            // setTimeout(() => {
            //     this.setState({
            //         alert_verification_phone: false,
            //     })
            // }, 3000) 
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Please add a valid contact number.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!this.state.contact_email.match(pattern)){
            // this.setState({
            //     emailValidation: true   
            // })
            // setTimeout(() => {
            //     this.setState({
            //         emailValidation: false
            //     })
            // }, 3000);
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Please add a valid email address.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return; 
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "id": this.state.id,
        "list_id": this.state.contact_list_id,
        "first_name": this.state.contact_first_name,
        "last_name": this.state.contact_last_name,
        "contact_email": this.state.contact_email,
        "contact_country": this.state.contact_country,
        "contact_mobile": this.state.contact_mobile,
        "contact_status": this.state.contact_status
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/update/`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.tog_edit()
                this.viewAllContacts()
                this.setState({
                    id: '',
                    contact_list_id: '',
                    contact_first_name: '',
                    contact_last_name: '',
                    contact_email: '',
                    contact_country: '',
                    contact_mobile: '',
                    contact_status: ''
                })
                // this.setState({
                //     updateCont: true
                // })
                // setTimeout(() => {
                //     this.setState({
                //         updateCont: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Contact updated successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                this.viewAllContacts()
            }
            else{
                // this.setState({
                //     updateContFail: true,
                   
                // })
                this.tog_edit()
                // setTimeout(() => {
                //     this.setState({
                //         updateContFail: false
                //     })
                // }, 3000)
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Contact could not be updated.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-error'
             })
            }
        })
        .catch(error => console.log('error', error));
    }

    deleteContacts(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${url}/contacts/delete/` + this.state.id, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode==="200"){
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Contact deleted successfully.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
         })
        }else{
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Contact could not be deleted.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
            this.viewAllContacts()
        })
        .catch(error => console.log('error', error));
    }

    fillContacts(contacts){
        this.tog_edit()
        this.setState({
            id: contacts.id,
            contact_list_id: contacts.list_id,
            contact_first_name: contacts.contact_first_name,
            contact_last_name: contacts.contact_last_name,
            contact_email: contacts.contact_email,
            contact_country: contacts.contact_country,
            contact_mobile: contacts.contact_mobile,
            contact_status: contacts.contact_status,
            prev_list_id: contacts.list_id,
            prev_first_name: contacts.contact_first_name,
            prev_last_name: contacts.contact_last_name,
            prev_email: contacts.contact_email,
            prev_country: contacts.contact_country,
            prev_mobile: contacts.contact_mobile,
            prev_status: contacts.contact_status,
            prev_id: contacts.id,
        })

    }

    tog_add() {
        if (this.state.modal_add === true) {
            this.setState({
                contact_list_id: '',
                contact_first_name: '',
                contact_last_name: '',
                contact_email: '',
                contact_country: '',
                contact_mobile: '',
                contact_status: ''
            })
        }
        this.setState(prevState => ({
            modal_add: !prevState.modal_add

        }));
    }

    tog_add_file() {
        
        this.setState(prevState => ({
            modal_add_file: !prevState.modal_add_file,
           selectedFile: null
        }));
    }

    tog_edit() {
        this.setState(prevState => ({
            modal_edit: !prevState.modal_edit
        }));
        if (this.state.modal_edit === true) {
            this.setState({
                id: '',
                contact_list_id: '',
                contact_first_name: '',
                contact_last_name: '',
                contact_email: '',
                contact_country: '',
                contact_mobile: '',
                contact_status: ''
            })
        }
    }

    clearFilter(){
        this.setState({
            country_filter: '',
            contact_status_filter: '',
            contact_created_at: '',
            created_at_filter: '',
            contact_list_id_filter: ''
        })
        setTimeout(() => {
            this.viewAllContacts()
        }, 100)
    }

     saveExcel = async () => {
        const workbook = new Excel.Workbook();

        try {
          const fileName = "SampleContact";
    
          const worksheet = workbook.addWorksheet("contact");
    
          worksheet.columns = [
            // { header: 'list_id', key: 'list_id' },
            { header: 'contact_first_name', key: 'contact_first_name' },
            { header: 'contact_last_name', key: 'contact_last_name' },
            { header: 'contact_country', key: 'contact_country' },
            { header: 'contact_email', key: 'contact_email' },
            { header: 'contact_mobile', key: 'contact_mobile' },
            // { header: 'contact_status', key: 'contact_status' }
          ];
    
          worksheet.getRow(1).font = { bold: true };
    
          // write the content using writeBuffer
          const buf = await  workbook.csv.writeBuffer();
    
          // download the processed file
          saveAs(new Blob([buf]), `${fileName}.csv`);
        } catch (error) {
          console.error('<<<ERRROR>>>', error);
          console.error('Something Went Wrong', error.message);
        } finally {
          // removing worksheet's instance to create new one
          workbook.removeWorksheet("contact");
        }
      };

      bulkDelete(){
        this.setState({
            isLoading: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "array_id": this.state.checkedId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/contacts/bulkdelete`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode === "200"){
                this.setState({
                    checkedId: [],
                })
                toast(<div className='toast-message-container'>
                <h6>Contacts</h6>
                <p>Contacts deleted successfully.</p>
                </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-success'
                })
                this.viewAllContacts()
            }
            
        })
        
        .catch(error => console.log('error', error));
      }
      bulkimportToList(e){
        if(e.target.checked){
            var array = []
            for(let i = 0; i < this.state.contacts.length; i++){
                array.push(this.state.contacts[i].id)
            }
            this.setState({ bulkimport: true })
            this.setState({
                checkedId: array,
            })
            
        }
        else{
            this.setState({
                checkedId: [],
                bulkimport: false 
            })
        }
       
        this.viewAllContacts()
      }
      disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Contacts</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const columns= [
            
            {
                name: 'ID',
                selector: 'id',
                sortable: true,
        
            },
            {
                name: 'First Name',
                selector: 'contact_first_name',
                sortable: true,
        
            },
            {
                name: 'Last Name',
                selector: 'contact_last_name',
                sortable: true,
        
            }, 
            {
                name: 'Country',
                selector: 'contact_country',
                sortable: true,
                grow: 1
        
            },
            {
                name: 'Email',
                selector: 'contact_email',
                sortable: true,
                grow: 3
        
            },
            {
                name: 'Phone No.',
                selector: 'contact_mobile',
                sortable: true,
                grow: 2
        
            },
            {
                name: 'Status',
                selector: 'contact_status',
                sortable: true,
        
            },  
            {
                name: 'Created At',
                selector: 'contact_created_at',
                sortable: true,
        
            }, {
                name: 'Updated At',
                selector: 'contact_updated_at',
                sortable: true,
        
            }, {
                name: 'Action',
                selector: 'button',
                sortable: false,
                grow: 2
        
            }
        ]
        const data = {
            columns: [
                {
                    label: <input type="checkbox" checked = {this.state.bulkimport ? true : false} style={{marginLeft: 10}} onChange={this.bulkimportToList} />,
                    field: 'check',
                    sort: 'disabled',
                    width: 20
                  },
                {
                    label: 'ID',
                    field: 'id',
                    width: 150
                },
                // {
                //     label: 'List Name',
                //     field: 'list_name',
                //     width: 270
                // },
                {
                    label: 'First Name',
                    field: 'contact_first_name',
                    width: 200
                },
                {
                    label: 'Last Name',
                    field: 'contact_last_name',
                    width: 10
                }, 
                {
                    label: 'Country',
                    field: 'contact_country',

                    width: 10
                },
                {
                    label: 'Email',
                    field: 'contact_email',
                    width: 10
                },
                {
                    label: 'Phone No.',
                    field: 'contact_mobile',
                    sort: "disabled",
                    width: 10
                },
                {
                    label: 'Status',
                    field: 'contact_status',
                    width: 10
                },  
                {
                    label: 'Created At',
                    field: 'contact_created_at',
                    width: 150
                }, {
                    label: 'Updated At',
                    field: 'contact_updated_at',
                    width: 100
                }, {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.contacts_filtered
        };
        const customStyles = {
            rows: {
                style: {
                    minHeight: '72px', // override the row height
                },
            },
            headCells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for head cells
                    paddingRight: '8px',
                    border: '1px solid #56555A',
                    borderBottom: '1px solid #56555A',
                    fontSize: '13px',
                    fontWeight: 600,
                    whiteSpace: 'normal !important'
                },
            },
            cells: {
                style: {
                    paddingLeft: '8px', // override the cell padding for data cells
                    paddingRight: '8px',
                    border: '1px solid #56555A',
                    whiteSpace: 'normal'
                },
            },
        };
        return (
            <React.Fragment>
                
                
                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Contact</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete" + " " + this.state.contact_first_name +  " " + this.state.contact_last_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteContacts();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                
                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        onClick={
                            this.tog_add
                        }
                        color="info"
                        className="waves-effect waves-light buttons-primary">
                       Add Contacts</Button>
                </div>

                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Country</Label>
                                        <Col>
                                            <select className="form-control" name='country_filter'
                                                value={
                                                    this.state.country_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    country.map((countries) => (
                                                        <option value={countries.name}>{countries.name}</option>
                                                    ))
                                                }
                                                
                                                
                                            </select>
                                        </Col>
                                    </Col>
                                
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='contact_status_filter'
                                                value={
                                                    this.state.contact_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">List Name</Label>
                                        <Col>
                                            <select className="form-control" name='contact_list_id_filter'
                                                value={
                                                    this.state.contact_list_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                     this.state.list.map((lists) => (
                                                        <option value={lists.id}>{lists.list_name}</option>
                                                    ))
                                                }  
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add_cal}>Custom</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className="text-left" style={{ marginTop: 10 }}>
                                    <Button type="button"
                                        onClick={
                                            this.viewAllContacts
                                        }
                                        color="warning"
                                        className="waves-effect waves-light buttons-primary">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>
                                    {
                                        this.state.created_at_filter === '' && this.state.contact_list_id_filter === '' && this.state.contact_status_filter === '' && this.state.country_filter === '' ?
                                        <Button type="button"
                                            disabled
                                            style={{ marginLeft: 10, cursor: "default" }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-disabled">

                                            Clear Filter
                                        </Button> :
                                        <Button type="button"
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-buttons">

                                            Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>
                                {/*
                                
                                */}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.createCont ? <Alert color="success">
                    <strong>Contacts created successfully.</strong>
                </Alert> : null}
                {this.state.updateCont? <Alert color="success">
                    <strong>Contacts updated successfully.</strong>
                </Alert> : null}
                {this.state.createContFail ? <Alert color="danger">
                    <strong>Contact already exists.</strong>
                </Alert> : null}
                {this.state.updateContFail ? <Alert color="danger">
                    <strong>Updation failed.</strong>
                </Alert> : null}
                {this.state.temp ? <Alert color="success">
                    <strong>{this.state.importCsvMessage}.</strong>
                </Alert> : null}
                {this.state.temp2 ? <Alert color="success">
                    <strong>Contacts have been move to list successfully.</strong>
                </Alert> : null}
                {this.state.bulkDeleteAlert ? <Alert color="success">
                    <strong>Contacts will be deleted shortly. If not please refresh the page.</strong>
                </Alert> : null}

                <Row>
                    <Col xs="12">
                        <Card>
                            <Row style={{padding: 20}}>
                                <Col xs="6">
                                    <Label for="example-password-input" className="col-form-label">Campaign List Name</Label>
                                    <FormGroup row className="formContainer">
                                        <Col sm="12">
                                            <select className="selectFormContainer mb-0" name='contact_list_ids'
                                                value={
                                                    this.state.contact_list_ids
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.list.map((lists) => (
                                                        <option value={lists.id}>{lists.list_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </FormGroup>
                                    {
                                        this.state.contact_list_ids === '' || this.state.checkedId.length === 0 ?
                                        <Button color="primary" disabled style={{cursor: "default"}} onClick={this.pushContacts}>Add to List</Button>
                                        : <Button color="primary" onClick={this.pushContacts}>Add to List</Button>
                                    }
                                    {
                                        this.state.checkedId.length === 0 ?
                                        <Button color="danger" disabled onClick={this.bulkDelete} style={{marginLeft: 10}}>Bulk Delete</Button>
                                        :
                                        <Button color="danger" onClick={this.bulkDelete} style={{marginLeft: 10}}>Bulk Delete</Button>
                                    }
                                    
                                    
                                </Col>
                               
                                <Col xs="6" style={{marginTop: 10, display: "flex", justifyContent: "flex-end", height: "40px"}}>
                                    
                                    <Button color="primary" onClick={this.tog_add_file}>Import Contacts</Button>
                                </Col>
                            </Row>
                            <CardBody >
                            
                             <DataTable 
                                columns={columns} 
                                data={this.state.contacts_filtered} 
                                pagination 
                                //title="Countries Data" 
                                fixedHeader 
                                fixedHeaderScrollHeight="500px" 
                                selectableRows
                                selectableRowsHighlight
                                onSelectedRowsChange={this.handleCheckboxChange}
                                clearSelectedRows={this.state.toggledClearRows}
                                highlightOnHover
                                theme="solarized"
                                customStyles={customStyles}
                                
                                // actions={<button onClick={()=>giveAlert()}>Export</button>}
                                subHeader
                                subHeaderComponent={<input 
                                    value={this.state.search}
                                    onChange={(e)=>this.setSearchFilter(e.target.value)}
                                    placeholder="Search"
                                    style={{border: "1px solid #7c7b80",background:"#474548",color:"silver", paddingLeft:"5px",paddingTop:"3px",paddingBottom:"3px",borderRadius:"3px"}}
                                    />}
                                subHeaderAlign="right"
                                />
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/*Create List*/}
                
                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Add Details
                        </ModalHeader>
                        <ModalBody>
                            {this.state.alert_verification_phone ? <Alert color="danger">
                                <strong>Please provide a valid mobile number</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="contact_first_name"
                                    className="w-100"
                                    label="First Name* (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter First Name"
                                    type="text"
                                    errorMessage="Enter First Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.contact_first_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="contact_last_name"
                                    className="w-100"
                                    label="Last Name* (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter Last Name"
                                    type="text"
                                    errorMessage="Enter Last Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.contact_last_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                {this.state.emailValidation ? <Alert color="danger">
                                <strong>Please provide valid email.</strong>
                            </Alert> : null}
                                <AvField
                                    name="contact_email"
                                    label="E-Mail  "
                                    placeholder="Enter Valid Email"
                                    type="email"
                                    className="w-100"
                                    errorMessage="Invalid Email"
                                    value={
                                        this.state.contact_email
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                
                            </AvForm>
                            <Label for="example-password-input" className=" col-form-label">Country *</Label>
                            <FormGroup row className="formContainer">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='contact_country'
                                        value={
                                            this.state.contact_country
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            country.map((countries) => (
                                                <option value={countries.name}>{countries.name}</option>
                                            ))
                                        }
                                        
                                        
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className="col-form-label">Phone Number *</Label>
                            
                                <PhoneInput 
                                    className="w-100 mb-2"
                                    country={'ae'}
                                    isValid ={true}
                                    value={this.state.contact_mobile}
                                    onChange={contact_mobile => this.setState({ contact_mobile })}
                                />
                            <Label for="example-password-input" className=" col-form-label">Campaign List Name *</Label>
                            <FormGroup row className="formContainer">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='contact_list_id'
                                        value={
                                            this.state.contact_list_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.list.map((lists) => (
                                                <option value={lists.id}>{lists.list_name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className=" col-form-label">Status *</Label>
                            <FormGroup row className="formContainer">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='contact_status'
                                        value={
                                            this.state.contact_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add
                                }>Close
                            </Button>
                            {
                                this.state.contact_status === "" || this.state.contact_email === "" || this.state.contact_mobile === "" || this.state.contact_country === "" || this.state.contact_email === "" || this.state.contact_first_name === "" || this.state.contact_last_name === "" || this.state.contact_list_id === "" ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createContacts()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add Contact
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createContacts()
                                        }
                                        className="waves-effect waves-light">Add Contact</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Import csv*/}
                
                <Row>
                    <Modal isOpen={
                        this.state.modal_add_file
                    }
                        toggle={
                            this.tog_add_file
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader >
                            Add File
                        </ModalHeader>
                        <ModalBody>
                        <Row>
                        <Col xs="12">
                        <Card>
                            <CardBody>
                                <Form style={{
                                    borderStyle: "dotted",
                                    padding: 50
                                }}>
                                    <input type='file' style={{width: 500, fontSize: "15px", marginLeft: "191px"}} onChange={this.onFileChange} accept=".csv"/>
                                    <p style={{display: "flex", justifyContent: "center", marginTop: 10}}>Supports .csv format only.</p>
                                </Form>
                                <div className="text-center mt-4">
                                    {
                                        this.state.selectedFile === null ?
                                        <Button type="button" disabled color="primary" onClick={() => this.importFile()} className="waves-effect waves-light">Import File</Button>
                                        : <Button type="button" color="primary" onClick={() => this.importFile()} className="waves-effect waves-light">Import File</Button>
                                    }
                                    
                                </div>
                            </CardBody>
                        </Card>
                        </Col>
                    </Row> 
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" style={{marginRight: 10}} onClick={this.saveExcel}>Download csv format</Button>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_add_file
                                }>Close
                            </Button>
                    </ModalFooter>
                    </Modal>
                </Row>
               
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit
                    }
                        toggle={
                            this.tog_edit
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            {this.state.alert_verification_phone ? <Alert color="danger">
                                <strong>Please provide a valid mobile number</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="contact_first_name"
                                    className="w-100"
                                    label="First Name* (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter First Name"
                                    type="text"
                                    errorMessage="Enter First Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.contact_first_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="contact_last_name"
                                    className="w-100"
                                    label="Last Name* (50 characters max.)"
                                    maxLength="50"
                                    placeholder="Enter Last Name"
                                    type="text"
                                    errorMessage="Enter Last Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.contact_last_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                  {this.state.emailValidation ? <Alert color="danger">
                                <strong>Please provide valid email.</strong>
                            </Alert> : null}
                                <AvField
                                    name="contact_email"
                                    label="E-Mail  "
                                    placeholder="Enter Valid Email"
                                    type="email"
                                    className="w-100"
                                    errorMessage="Invalid Email"
                                    value={
                                        this.state.contact_email
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                
                            </AvForm>
                            <Label for="example-password-input" className=" col-form-label">Country *</Label>
                            <FormGroup row className="formContainer">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='contact_country'
                                        value={
                                            this.state.contact_country
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            country.map((countries) => (
                                                <option value={countries.name}>{countries.name}</option>
                                            ))
                                        }
                                        
                                        
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className=" col-form-label">Phone number *</Label>
                            <PhoneInput
                                   dropdownStyle={{ backgroundColor: '#474548' }}
                                    country={'ae'}
                                    className="w-100"
                                    value={this.state.contact_mobile}
                                    onChange={contact_mobile => this.setState({ contact_mobile })}
                                />
                                <Label for="example-password-input" className="col-form-label">Campaign List Name *</Label>
                                <FormGroup row className="formContainer">
                                    <Col sm="12">
                                        <select className="selectFormContainer mb-0 w-100" name='contact_list_id'
                                            value={
                                                this.state.contact_list_id
                                            }
                                            onChange={
                                                this.changeHandler
                                            }>
                                            <option value="0" selected hidden>Select</option>
                                            {
                                                this.state.list.map((lists) => (
                                                    <option value={lists.id}>{lists.list_name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </FormGroup>
                            <Label for="example-password-input" className="col-form-label">Status *</Label>
                            <FormGroup row className="formContainer">
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='contact_status'
                                        value={
                                            this.state.contact_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit
                                }>Close
                            </Button>
                            {
                                this.state.contact_status === this.state.prev_status && this.state.contact_mobile === this.state.prev_mobile && this.state.contact_country === this.state.prev_country && this.state.contact_email === this.state.prev_email && this.state.contact_first_name === this.state.prev_first_name && this.state.contact_last_name === this.state.prev_last_name && this.state.contact_list_id === this.state.prev_list_id ?
                                    <Button type="button" color="primary" disabled
                                    style={{cursor: "default"}}
                                        onClick={
                                            () => this.updateContacts()
                                        }
                                        
                                        className="waves-effect waves-light">Update Contact
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateContacts()
                                        }
                                        className="waves-effect waves-light">Update Contact</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add_cal
                    }
                        toggle={
                            this.tog_add_cal
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add_cal2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllContacts
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewAllContacts
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewAllContacts
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
            </React.Fragment>
            
        );
    }
}

export default connect(null, { setBreadcrumbItems })(MarketingContacts);